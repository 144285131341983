import React from 'react';

import '../tailwind.css';
import '../css/site.css';
import bg from '../images/landing1.jpg';

const IndexPage = () => {
  return (
    <div>
      <title>Noggin Labs</title>
      <div className="flex flex-col bg-cover bg-center h-screen" style={{ backgroundImage: `url(${bg})` }}>
        <div className="flex h-full justify-center items-center">Coming soon</div>
        <div className="flex">
          <div className="flex flex-grow justify-center">&copy; 2021</div>
          <div className="flex p-2">
            <span title="{{buildinfo}}">&#960;</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexPage;
